<template>
  <v-dialog v-model="modalOpen" max-width="500" scrollable>
    <template v-slot:activator="{on}">
      <CreationButton v-on="on" @click="openModal" :type="decryptedType" />
    </template>
    <v-card class="rounded-lg px-5">
      <div class="flex items-center justify-between py-2 border-b">
        <h1 class="text-xl font-semibold">Créer un nouveau emplois de temps</h1>
        <img :src="Button" class="ml-auto cursor-pointer" @click="closeModal" />
      </div>
      <div class="mt-4">
        <ModalsDropDown
          v-for="(field, index) in fields"
          :key="index"
          :title="field.label"
          :options="field.options"
          :error="field.error"
          @option-selected="updateSelectedOption(index, $event)"
        />
        <v-checkbox
          color="secondary"
          label="Accès collaboratif"
          class="text-black"
          v-model="acces"
        ></v-checkbox>
        <span class="text-red-500 text-sm mt-2" v-if="errorMsg">{{
          errorMsg
        }}</span>
      </div>
      <v-card-actions class="gap-2 flex justify-end mt-2">
        <button
          class="bg-gray-300 rounded-full px-6 outline-none text-black font-inter text-sm font-semibold leading-10 tracking-normal text-center"
          @click="closeModal"
        >
          Annuler
        </button>
        <button
          class="bg-blue-700 rounded-full px-6 outline-none font-inter text-sm font-semibold leading-10 tracking-normal text-center text-white"
          @click="addEmploiTemps"
        >
          Créer
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import plus from '../../../assets/plus-sm.png';
import Button from '../../../assets/Button.png';
import ModalsDropDown from '../../DropDown/ModalsDropDown.vue';
import CreationButton from '../../Buttons/CreationButton/CreationButton.vue';
import CryptoJS from 'crypto-js';
import {
  ADD_EMPLOIS_TEMPS,
  GET_DEPARTEMENTS,
  GET_CLASSES_BY_DEPARTEMENT,
} from './ModalEmploisDeTemps.service.js';
import apolloClient from '../../../apolloClient';
export default {
  components: {
    ModalsDropDown,
    CreationButton,
  },
  data() {
    return {
      modalOpen: false,
      plus,
      Button,
      decryptedType: '',
      fields: [
        {label: 'Département', options: [], error: ''},
        {label: 'Classe', options: [], error: ''},
        {label: 'Année universitaire', options: [], error: ''},
        {
          label: 'Semestre',
          options: [
            {id: 'S1', label: 'S1'},
            {id: 'S2', label: 'S2'},
          ],
          error: '',
        },
      ],
      selectedOptions: {
        departement: '',
        classe: '',
        annee_universitaire: '',
        semestre: '',
      },
      errorMsg: '',
      acces: false,
      error: '',
    };
  },
  methods: {
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    availableYears() {
      const anneeEnCours = new Date().getFullYear();
      const anneeSuivante = anneeEnCours + 1;
      const annees_universitaires = [
        {
          id: `${anneeEnCours - 1}-${anneeEnCours}`,
          label: `${anneeEnCours - 1}-${anneeEnCours}`,
        },
        {
          id: `${anneeEnCours}-${anneeSuivante}`,
          label: `${anneeEnCours}-${anneeSuivante}`,
        },
      ];
      this.fields[2].options = annees_universitaires;
    },
    validateChamps() {
      let isValid = true;

      this.fields.forEach(field => {
        field.error = '';
        switch (field.label) {
          case 'Département':
            if (!this.selectedOptions.departement) {
              field.error = 'Le champ Département est obligatoire.';
              isValid = false;
            }
            break;
          case 'Classe':
            if (!this.selectedOptions.classe) {
              field.error = 'Le champ Classe est obligatoire.';
              isValid = false;
            }
            break;
          case 'Année universitaire':
            if (!this.selectedOptions.annee_universitaire) {
              field.error = 'Le champ Année universitaire est obligatoire.';
              isValid = false;
            }
            break;
          case 'Semestre':
            if (!this.selectedOptions.semestre) {
              field.error = 'Le champ Semestre est obligatoire.';
              isValid = false;
            }
            break;
        }
      });

      return isValid;
    },
    clearError() {
      this.error = '';
    },
    async addEmploiTemps() {
      try {
        if (!this.validateChamps()) {
          return;
        }

        const response = await apolloClient.mutate({
          mutation: ADD_EMPLOIS_TEMPS,
          variables: {
            departement_id: parseInt(this.selectedOptions.departement),
            classe_id: parseInt(this.selectedOptions.classe),
            annee_universitaire: this.selectedOptions.annee_universitaire,
            semestre: this.selectedOptions.semestre,
            acces: this.acces,
          },
        });
        if (response.data.addEmploiTemps.success === false) {
          this.errorMsg = response.data.addEmploiTemps.message;
        } else {
          this.$emit('EmploiAdded');
          this.closeModal();
        }
      } catch (error) {
        console.error("Une erreur s'est produite :", error);
      }
    },
    updateSelectedOption(index, value) {
      let fieldName = this.fields[index].label.toLowerCase();
      fieldName = fieldName
        .replace(/\s/g, '_')
        .replace(/[é]/g, 'e')
        .replace(/[^a-zA-Z0-9_]/g, '_');
      this.selectedOptions[fieldName] = value;
      this.getClasses();
    },
    async getDepartements() {
      try {
        const response = await apolloClient.query({
          query: GET_DEPARTEMENTS,
        });
        this.fields[0].options = response.data.getAllDepartements.map(
          departement => ({
            id: departement.id,
            label: departement.label,
          })
        );
      } catch (error) {
        console.error('erreur de recuperation departements :', error);
      }
    },
    async getClasses() {
      try {
        const response = await apolloClient.query({
          query: GET_CLASSES_BY_DEPARTEMENT,
          variables: {
            id: parseInt(this.selectedOptions.departement),
          },
        });
        this.fields[1].options = response.data.getClassesByDepartement.map(
          classe => ({
            id: classe.id,
            label: classe.label,
          })
        );
      } catch (error) {
        console.error('erreur de recuperation classes :', error);
      }
    },
  },
  mounted() {
    const typeCrypte = localStorage.getItem('type');
    const bytes = CryptoJS.AES.decrypt(typeCrypte, 'KEY');
    this.decryptedType = bytes.toString(CryptoJS.enc.Utf8);
    this.getDepartements();
    this.availableYears();
    this.getClasses();
  },
};
</script>
