<template>
  <v-card class="rounded-lg">
    <v-card-text>
      <div class="flex items-center justify-between py-2 mb-2 border-b">
        <h1 class="text-xl font-semibold">
          Salles {{ props.typeSalle == 'libre' ? 'Libres' : 'Occupées' }}
        </h1>
      </div>

      <v-alert
        :type="props.typeSalle == 'libre' ? 'info' : 'warning'"
        variant="tonal"
      >
        Veuillez noter que cette liste est issue des emplois du temps
        {{
          props.typeSalle == 'libre'
            ? 'qui ne sont pas encore diffusés'
            : 'diffusés'
        }}.
      </v-alert>

      <div class="flex mt-5 mb-5 gap-2 items-end">
        <div class="flex-1">
          <label class="mb-2">Jour</label>
          <select
            v-model="selectedJour"
            class="w-full px-4 py-2 border border-gray-300 rounded-md outline-none text-sm"
          >
            <option value="">Veuillez sélectionner</option>
            <option v-for="jour in jours" :key="jour.id" :value="jour.id">
              {{ jour.label }}
            </option>
          </select>
        </div>
        <div class="flex-1">
          <label class="mb-2">Séance</label>
          <select
            v-model="selectedSeance"
            class="w-full px-4 py-2 border border-gray-300 rounded-md outline-none text-sm"
            @change="handleSeanceChange"
          >
            <option :value="-1">
              {{
                props.typeSalle != 'libre'
                  ? 'Toute la journée'
                  : 'Veuillez sélectionner'
              }}
            </option>
            <template v-for="(seance, ind) in seances">
              <option
                :key="ind"
                :value="ind"
                v-if="seance.toLowerCase() != 'p'"
              >
                {{ seance }}
              </option>
            </template>
          </select>
        </div>
        <div class="flex-1">
          <label class="mb-2">Semestre</label>
          <select
            v-model="selectedSemestre"
            class="w-full px-4 py-2 border border-gray-300 rounded-md outline-none text-sm"
          >
            <option value="S1">S1</option>
            <option value="S1">S2</option>
          </select>
        </div>
        <div class="flex-1" v-if="typeSalle != 'libre'">
          <label class="mb-2">Département</label>
          <select
            v-model="selectedDepartement"
            class="w-full px-4 py-2 border border-gray-300 rounded-md outline-none text-sm"
          >
            <option value="0">Tout département</option>
            <option
              v-for="(option, index) in departements"
              :key="index"
              :value="option.id"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
        <div>
          <GradientButton
            class="rounded-lg"
            type="soft"
            color="blueDark"
            @click="getSalles()"
          >
            Valider
          </GradientButton>
        </div>
      </div>
      <div v-if="listerSalles" class="overflow-y-auto flex flex-col">
        <div class="text-end d-flex gap-2 justify-end" v-if="salles.length">
          <GradientButton
            class="rounded-lg"
            type="soft"
            color="blue"
            title="télécharger"
            v-if="props.typeSalle == 'occupe'"
            @click="telechargerSallesOccupes()"
          >
            <VIcon> mdi-file-excel </VIcon>
          </GradientButton>
          <GradientButton
            class="rounded-lg"
            type="soft"
            color="orange"
            @click="imprimerPage()"
          >
            <VIcon> mdi-printer </VIcon>
          </GradientButton>
        </div>

        <VTable class="table-strong-head">
          <thead>
            <tr>
              <th>Salle</th>
              <th>Bloc</th>
              <th v-if="props.typeSalle == 'occupe'">Enseignant</th>
              <th v-if="props.typeSalle == 'occupe'">Classe</th>
              <th v-if="props.typeSalle == 'occupe'">Horaire</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="salle in salles" :key="salle.label">
              <td>{{ salle.label }}</td>
              <td>{{ salle.bloc }}</td>
              <td v-if="salle.enseignant">
                {{ salle.enseignant.nom + ' ' + salle.enseignant.prenom }}
              </td>
              <td v-if="salle.classe" class="text-amber-600">
                {{ salle.classe.label }}
              </td>
              <td v-if="salle.horaire" class="text-success">
                {{ resolveHoraire(salle.horaire - 1) }}
              </td>
              <td v-if="props.typeSalle == 'occupe'">
                <router-link
                  :to="`/emploi-salle?id=${salle.id}`"
                  target="_blank"
                  class="print:hidden"
                >
                  <VBtn
                    icon="mdi-calendar-month"
                    size="x-small"
                    color="primary"
                    variant="tonal"
                  ></VBtn>
                </router-link>
              </td>
            </tr>
          </tbody>
        </VTable>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup>
import apolloClient from '@/apolloClient';
import {GET_SALLES_LIBRE_OCCUPE_EMPLOI} from '@/SPA/Enseignants/ModalEnsSeance/ModalEnsSeance.service';
import {GET_DEROULEMENT_SEANCE_BY_ETAB} from '@/SPA/Parametre/Parametre.services';
import {getCurrentSemestre} from '@/SPA/spa.services';
import {ref, defineProps} from 'vue';
import GradientButton from '@/components/Buttons/GradientButton/GradientButton.vue';
import {GET_DEPARTEMENTS} from '@/components/Modals/ModalEmploisDeTemps/ModalEmploisDeTemps.service';

const selectedJour = ref('');
const selectedSeance = ref(-1);
const selectedSemestre = ref();
const selectedDepartement = ref(0);
const departements = ref([]);
const salles = ref([]);
const seances = ref([]);
const listerSalles = ref(false);

const props = defineProps({
  typeSalle: {
    type: String,
    default: () => 'libre',
  },
});

const jours = [
  {id: 1, label: 'Lundi'},
  {id: 2, label: 'Mardi'},
  {id: 3, label: 'Mercredi'},
  {id: 4, label: 'Jeudi'},
  {id: 5, label: 'Vendredi'},
  {id: 6, label: 'Samedi'},
];

const getSalles = () => {
  apolloClient
    .query({
      query: GET_SALLES_LIBRE_OCCUPE_EMPLOI,

      fetchPolicy: 'no-cache',
      variables: {
        horaire: selectedSeance.value,
        jour: selectedJour.value,
        semestre: selectedSemestre.value,
        isLibre: props.typeSalle == 'libre' ? true : false,
        target_dept:
          props.typeSalle != 'libre'
            ? parseInt(selectedDepartement.value)
            : null,
      },
    })
    .then(res => {
      salles.value = res.data.getSallesLibreEmploi;
    })
    .catch(e => {
      console.error(e);
    });
};

const telechargerSallesOccupes = () => {
  window.open(
    process.env.VUE_APP_API_URL +
      '/Reporting.php?token=' +
      localStorage.getItem('token') +
      '&target=sallesOccupes'
  );
};
const getDeroulementSeances = () => {
  apolloClient
    .query({
      query: GET_DEROULEMENT_SEANCE_BY_ETAB,
    })
    .then(res => {
      seances.value = res.data.getDeroulementSeanceByEtab.horaire;
      listerSalles.value = true;
      selectedSemestre.value = getCurrentSemestre();
    })
    .catch(e => {
      console.error(e);
    });
};

const imprimerPage = () => {
  window.print();
};

const getDepartements = () => {
  apolloClient
    .query({
      query: GET_DEPARTEMENTS,
    })
    .then(({data}) => {
      departements.value = data.getAllDepartements;
    })
    .catch(e => {
      console.error('erreur de recuperation departements :', e);
    });
};

const resolveHoraire = horaire => {
  return seances.value[horaire] ?? 'not found';
};

getDeroulementSeances();
getDepartements();
</script>

<style>
.table-strong-head th {
  font-weight: bold !important;
}
</style>
