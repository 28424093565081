import gql from 'graphql-tag';
export const Get_All_BY_EMPLOI = gql`
  query getEmploiTempsById($id: Int) {
    getEmploiTempsById(id: $id) {
      id
      semestre
      departement {
        id
        label
      }
      classe {
        id
        label
        niveau
        id_specialite
        groupes {
          id
          label
        }
      }
      enseignants {
        id
        nom
        prenom
      }
      matieres {
        id_specialite
        id
        label
        natures
        niveau
        semestre
      }
    }
  }
`;
export const ADD_SEANCE = gql`
  mutation addSeance(
    $id_matiere: Int
    $id_enseignant: Int
    $id_salle: Int
    $id_groupe: Int
    $id_emploi: Int
    $jour: Int
    $horaire: Int
  ) {
    addSeance(
      id_matiere: $id_matiere
      id_enseignant: $id_enseignant
      id_salle: $id_salle
      id_groupe: $id_groupe
      id_emploi: $id_emploi
      jour: $jour
      horaire: $horaire
    ) {
      message
      success
    }
  }
`;
export const DELETE_SEANCE = gql`
  mutation deleteSeance($id: Int!) {
    deleteSeance(id: $id)
  }
`;

export const GET_ENSEIGNANTS_LIBRE_EMPLOI = gql`
  query getEnseignantsLibreEmploi(
    $query: String
    $dep_id: Int!
    $horaire: Int!
    $jour: Int!
    $semestre: String!
  ) {
    getEnseignantsLibreEmploi(
      dep_id: $dep_id
      horaire: $horaire
      jour: $jour
      semestre: $semestre
      query: $query
    ) {
      id
      nom
      etab
      prenom
    }
  }
`;

export const GET_SALLES_LIBRE_EMPLOI = gql`
  query getSallesLibreEmploi($horaire: Int!, $jour: Int!, $semestre: String!) {
    getSallesLibreEmploi(horaire: $horaire, jour: $jour, semestre: $semestre) {
      id
      label
      bloc
    }
  }
`;

export const GET_SALLES_LIBRE_OCCUPE_EMPLOI = gql`
  query getSallesLibreEmploi(
    $horaire: Int
    $jour: Int!
    $semestre: String!
    $isLibre: Boolean!
    $target_dept: Int
  ) {
    getSallesLibreEmploi(
      horaire: $horaire
      jour: $jour
      semestre: $semestre
      isLibre: $isLibre
      target_dept: $target_dept
    ) {
      id
      label
      bloc
      horaire
      enseignant {
        nom
        prenom
      }
      classe {
        label
      }
    }
  }
`;
export const UPDATE_SEANCE = gql`
  mutation updateSeance(
    $id: Int!
    $id_matiere: Int!
    $id_enseignant: Int!
    $id_salle: Int!
    $id_groupe: Int
    $id_emploi: Int
    $jour: Int!
    $horaire: Int!
  ) {
    updateSeance(
      id: $id
      id_matiere: $id_matiere
      id_enseignant: $id_enseignant
      id_salle: $id_salle
      id_groupe: $id_groupe
      id_emploi: $id_emploi
      jour: $jour
      horaire: $horaire
    ) {
      success
      message
    }
  }
`;
