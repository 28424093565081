import gql from "graphql-tag";
export const GET_SALLE = gql`
  query getAllSalles {
    getAllSalles {
      id
      label
      bloc
      etat
      dep_id {
        id
        label
      }
    }
  }
`;

export const GET_SALLES_FOR_EMPLPOI = gql`
  query getSallesForEmploi {
    getSallesForEmploi {
      id
      label
      bloc
      etat
      dep_id {
        id
        label
      }
    }
  }
`;
export const DELETE_SALLE = gql`
mutation deleteSalle($id:Int)
{
  deleteSalle(id: $id)
}
`;

export const LABEL_SALLE = gql`
query getSalleById($id: Int!) {
  getSalleById(id: $id) {
    label
  }
}`;

export const EMPLOI_SALLE = gql`
query EmploiSalle($id: Int!,$semestre: String!) {
  EmploiSalle(id: $id,semestre:$semestre) {
      id
      label_classe
      etab
      id_emploi
      jour
      horaire
      matiere {
        id
        label
        natures
      }
      enseignant {
        id
        nom
        prenom
      }
      salle {
        id
        label
      }
      group {
        id
        label
      }
  }
}
`;
